@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap);
:root {
	/* Colors */
	--body-color: #ffffff;
	--primary-color: #0f8685;
	--secondary-color: #0a7271;
	--tertiary-color: #6f6f6f;
	--container-color: #f3f3f3;
	--container-colorhighlight: #e6ebeb;
	--text-color: #535353;
	--input-color: #ffffff;
	--border-color: #747474;
	--result-color-market: #6f6f6f;
	--result-color-partner: #4f8c91;
	--result-color-saved: var(--primary-color);
	--strategy-color1: rgb(78, 141, 78);
	--strategy-color2: rgb(240, 189, 94);
	--strategy-color3: rgb(247, 109, 109);
	--strategy-color4: rgb(248, 69, 69);
	--strategy-color5: rgb(180, 13, 13);
	--strategy-color6: rgb(85, 0, 0);
	--error-color: rgb(247, 142, 142);

	/* Font */
	--font-family: "Open Sans", Arial, Helvetica, sans-serif;
	--font-size: 16px;
}

/* Generel */
body {
	background-color: var(--body);
	color: #535353;
	color: var(--text-color);
	margin: 0;
}

.oba-container * {
	font-family: "Open Sans", Arial, Helvetica, sans-serif !important;
	font-family: var(--font-family) !important;
	font-size: 16px;
	font-size: var(--font-size);
}

a {
	color: #0f8685;
	color: var(--primary-color);
	text-decoration: underline;
}

/* Typography */
.oba-container h3 {
	font-size: 1.4em;
}

.oba-container h4 {
	font-size: 1.1em;
	font-weight: 600;
	margin: 0 0 6px;
}

/* Layout */
.loader {
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--body);
}

.loader img {
	width: 40px;
	height: 40px;
}

.oba-container {
	display: flex;
	flex-direction: column;
}

.oba-container > div {
	width: 100%;
}

/* Input */
.oba-input > div {
	margin-bottom: 25px;
	padding: 0 25px;
	position: relative;
}

.oba-input div > a.help {
	margin-left: 10px;
	display: flex;
}

.oba-input div > a.help:hover + .helpBubble,
.oba-input div > a.help:active + .helpBubble {
	display: block;
	transition: all 100ms linear;
	opacity: 1;
}

.oba-input div > a.help + .helpBubble {
	transition: all 100ms linear;
	opacity: 0;
	display: none;
	position: absolute;
	background: #e6ebeb;
	background: var(--container-colorhighlight);
	right: -25px;
	padding: 15px 30px;
	z-index: 1;
	border-radius: 5px;
	top: 42px;
	width: 200px;
	z-index: 2;
	font-size: 0.9em;
	box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
		0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
		0 22.3px 17.9px rgba(0, 0, 0, 0.072),
		0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
}

.oba-input div > a.help + .helpBubble:before {
	content: "";
	position: absolute;
	border-top: 13px solid transparent;
	border-left: 13px solid transparent;
	border-right: 13px solid transparent;
	border-bottom: 13px solid #e6ebeb;
	border-bottom: 13px solid var(--container-colorhighlight);
	border-radius: 4px;
	top: -24px;
	right: 22px;
	z-index: 2;
	pointer-events: none;
}

.oba-input div > a.help img {
	width: 20px;
	cursor: pointer;
}

.oba-input > div > div > div:first-child {
	width: 100%;
	border: 1px solid #6f6f6f;
	border: 1px solid var(--tertiary-color);
	border-radius: 4px;
	background-color: #ffffff;
	background-color: var(--input-color);
	color: #535353;
	color: var(--text-color);
	display: flex;
}

/* Amount input */
.oba-input .oba-input-amount {
	position: relative;
	display: flex;
}

.oba-input .oba-input-amount > div:first-child {
	padding: 5px 10px;
}

.oba-input .oba-input-amount input {
	flex: 1 1;
	border: 0;
	text-align: right;
}

.oba-input .oba-input-amount input:focus {
	outline: none;
}

.oba-input .oba-input-amount span {
	align-self: center;
	padding-left: 4px;
}

/* Strategy input */
.oba-input .oba-input-strategy {
	position: relative;
	display: flex;
}

.oba-input .oba-input-strategy > div:first-child {
	overflow: hidden;
	border: 0;
}

.oba-input .oba-input-strategy input[type="radio"] + label {
	flex: 1 1;
	padding: 7px 12px 8px;
	-webkit-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	background-color: #e6ebeb;
	background-color: var(--container-colorhighlight);
	cursor: pointer;
	text-align: center;
	font-weight: 600;
}

.oba-input .oba-input-strategy input[type="radio"] {
	position: absolute;
	width: 1px;
	height: 1px;
	opacity: 0;
	left: -2000px;
}

.oba-input .oba-input-strategy input[type="radio"]:checked + label {
	transition: all 200ms linear;
	color: #ffffff;
	color: var(--input-color);
}

.oba-input
	.oba-input-strategy
	input[type="radio"]:nth-child(1):checked
	+ label {
	background-color: rgb(78, 141, 78);
	background-color: var(--strategy-color1);
}

.oba-input
	.oba-input-strategy
	input[type="radio"]:nth-child(3):checked
	+ label {
	background-color: rgb(240, 189, 94);
	background-color: var(--strategy-color2);
}

.oba-input
	.oba-input-strategy
	input[type="radio"]:nth-child(5):checked
	+ label {
	background-color: rgb(247, 109, 109);
	background-color: var(--strategy-color3);
}

.oba-input
	.oba-input-strategy
	input[type="radio"]:nth-child(7):checked
	+ label {
	background-color: rgb(248, 69, 69);
	background-color: var(--strategy-color4);
}

.oba-input
	.oba-input-strategy
	input[type="radio"]:nth-child(9):checked
	+ label {
	background-color: rgb(180, 13, 13);
	background-color: var(--strategy-color5);
}

.oba-input
	.oba-input-strategy
	input[type="radio"]:nth-child(10):checked
	+ label {
	background-color: rgb(85, 0, 0);
	background-color: var(--strategy-color6);
}

/* Result */
.oba-result > div > div,
.oba-result .oba-result-information {
	padding: 35px 25px 30px 25px;
}

.oba-result .oba-result-information a {
	color: #ffffff !important;
	color: var(--input-color) !important;
}

.oba-result h3,
.oba-result h5 {
	margin: 0;
	display: flex;
}

.oba-result h3 span,
.oba-result h5 span {
	color: #ffffff;
	color: var(--input-color);
	flex: 1 1;
}

.oba-result h5 span:last-child {
	flex: none;
}

.oba-result h3 span {
	font-size: 1em;
	font-weight: 600;
}

.oba-result h3 span:last-child {
	font-weight: 700;
}

.oba-result h5 span {
	font-size: 16px;
	font-weight: 600;
}

.oba-result span:last-child {
	text-align: end;
}

.oba-result-market {
	background-color: #6f6f6f;
	background-color: var(--result-color-market);
	position: relative;
}

.oba-result-partner {
	background-color: #4f8c91;
	background-color: var(--result-color-partner);
	position: relative;
}

.oba-result-saved {
	background-color: #0f8685;
	background-color: var(--result-color-saved);
	position: relative;
	padding: 35px 25px 35px 25px !important;
}

.oba-result-information {
	position: relative;
}

.oba-result-market:after,
.oba-result-partner:after,
.oba-result-saved:after,
.oba-result-information:after {
	content: "";
	position: absolute;
	right: 15%;
	bottom: 0;
	width: 0;
	height: 0;
	border-left: 15px solid transparent;
	border-right: 15px solid transparent;
	border-top: 15px solid transparent;
	margin-bottom: -15px;
	z-index: 1;
}

.oba-result-saved:after {
	display: none;
}

.oba-result-market:after {
	border-top: 15px solid #6f6f6f;
	border-top: 15px solid var(--result-color-market);
}

.oba-result-partner:after {
	border-top: 15px solid #4f8c91;
	border-top: 15px solid var(--result-color-partner);
}

.oba-result-saved:after,
.oba-result-information:after {
	border-top: 15px solid #0f8685;
	border-top: 15px solid var(--result-color-saved);
	right: calc(50% - 7px);
}

.oba-result-saved > h2 {
	margin: 0;
}

.oba-result-saved > h2 span {
	margin: 0;
	display: flex;
	flex-direction: column;
	color: #ffffff;
	color: var(--input-color);
	text-align: center !important;
}

.oba-result-saved > h2 span:first-child {
	font-size: 36px;
}

.oba-result-saved > h2 span:last-child {
	font-weight: 600;
}

.oba-result-information {
	background-color: #0f8685;
	background-color: var(--result-color-saved);
	padding-top: 0 !important;
	display: block;
}

.oba-result-information h3,
.oba-result-information p {
	color: #ffffff;
	color: var(--input-color);
	display: block !important;
}

.oba-result .oba-result-information p a {
	color: #ffffff;
	color: var(--input-color);
}

.oba-result-information div span {
	display: block;
	width: 20px;
	height: 20px;
	margin-right: 20px;
	align-self: center;
}

.oba-result-information div span:before {
	content: "";
	width: 20px;
	height: 20px;
	display: block;
	background-size: contain;
	background-image: url(/static/media/helpwhite.1da1da8e.svg);
	background-repeat: no-repeat;
}

.oba-result-information h3 {
	display: block;
	text-align: center;
}

.oba-result-contact {
	background-color: #f3f3f3;
	background-color: var(--container-color);
	display: flex;
	flex-direction: column;
	align-items: center;
}

.oba-result-contact h2 {
	font-size: 2.4em;
	color: #0f8685;
	color: var(--primary-color);
	padding: 30px 40px 40px;
	margin: 0;
	text-align: center;
}

.oba-result-contact label {
	font-size: 1.1em;
	font-weight: 600;
	margin: 0 0 6px;
}

.oba-result-contact > div {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	max-width: 500px;
}

.oba-result-contact > div > div {
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
}

.oba-result-contact input[type="text"],
.oba-result-contact textarea,
.oba-result-contact input[type="email"],
.oba-result-contact input[type="phone"] {
	border: 1px solid #6f6f6f;
	border: 1px solid var(--tertiary-color);
	border-radius: 4px;
	background-color: #ffffff;
	background-color: var(--input-color);
	color: #535353;
	color: var(--text-color);
	padding: 6px 12px;
}

.oba-result-contact div.terms {
	display: flex;
	flex-direction: row;
}

.oba-result-contact div.comment {
	flex-basis: 100%;
	margin-right: 0 !important;
}

.oba-result-contact div.comment textarea {
	min-height: 60px;
	padding: 10px;
}

.oba-result-contact div.receipt {
	width: 100%;
	padding-left: 55px !important;
}

.oba-result-contact div.terms input[type="checkbox"] {
	margin-top: 5px;
	margin-right: 15px;
}

.oba-result-contact div.terms label {
	font-weight: 400;
}

.oba-result-contact p.error {
	background-color: rgb(247, 142, 142);
	background-color: var(--error-color);
	color: #ffffff;
	color: var(--input-color);
	padding: 10px 20px;
	margin-bottom: 20px;
	border-radius: 4px;
}

.oba-result-contact > div > div a {
	display: block;
}

.oba-result-contact > div > div:last-child {
	display: flex;
	align-items: center;
	flex-basis: 100%;
}

.oba-result-contact button {
	border: 0;
	font-weight: 700;
	text-transform: uppercase;
	background-color: #0f8685;
	background-color: var(--primary-color);
	color: #ffffff;
	color: var(--input-color);
	padding: 12px 50px;
	margin-bottom: 30px;
}

.oba-result-contact button:disabled {
	opacity: 0.5;
}

.oba-footer {
	display: none;
	background-color: #f3f3f3;
	background-color: var(--container-color);
	margin-top: 30px;
	padding: 40px;
	box-sizing: border-box;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
	.oba-input {
		display: flex;
		justify-content: space-between;
	}

	.oba-input > div {
		width: 48%;
	}

	.oba-result {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.oba-result > div {
		display: flex;
		width: 100%;
	}

	.oba-result > div:last-child > div:last-child {
		order: 1;
	}

	.oba-result > div:last-child > div:first-child {
		order: 2;
	}

	.oba-result > div:first-child > div {
		padding: 25px 15px 20px 15px !important;
		flex: 1 1;
	}

	.oba-result > div:first-child > div:last-child {
		flex: 1.11 1;
	}

	.oba-result-market h3,
	.oba-result-partner h3 {
		font-size: 1.1em;
	}

	.oba-result-market h5 span,
	.oba-result-partner h5 span {
		font-size: 0.8em;
	}

	.oba-result-saved > h2 span:first-child {
		font-size: 26px;
	}

	.oba-result-saved > h2 span:last-child {
		font-weight: 600;
	}

	.oba-result-saved:after {
		display: block;
	}

	.oba-result > div:last-child > div:last-child {
		flex: 2 1;
	}

	.oba-result > div:last-child > div:first-child {
		flex: 1 1;
	}

	.oba-result-market:after,
	.oba-result-partner:after {
		transform: rotate(270deg);
		right: -20px;
		top: 45%;
	}

	.oba-result-information:after {
		display: none;
	}

	.oba-result-information {
		background-color: #e6ebeb;
		background-color: var(--container-colorhighlight);
		padding-top: 70px !important;
	}

	.oba-result-information h3,
	.oba-result-information p,
	.oba-result .oba-result-information a {
		color: #535353 !important;
		color: var(--text-color) !important;
	}

	.oba-result-information div span:before {
		background-image: url(/static/media/helpgrey.2ab149ab.svg);
	}

	.oba-result-contact h2 {
		font-size: 2em;
		padding: 10px 20px 30px;
		align-self: flex-start;
	}

	.oba-result .oba-result-contact > div {
		padding: 0 20px;
	}

	.oba-result .oba-result-saved h2 {
		position: relative;
		top: 10px;
	}

	.oba-result .oba-result-saved:after {
		bottom: -30px;
	}

	.oba-result .oba-result-saved > div {
		height: 30px;
		width: 100%;
		left: 0;
		position: absolute;
		bottom: -30px;
		background-color: #0f8685;
		background-color: var(--primary-color);
		z-index: 1;
	}
}

@media (min-width: 880px) {
	.oba-container {
		align-items: center;
	}

	.oba-container > div {
		max-width: 1024px;
	}

	.oba-result-market h3,
	.oba-result-partner h3 {
		font-size: 1.3em;
	}

	.oba-result-market h5 span,
	.oba-result-partner h5 span {
		font-size: 0.9em;
	}

	.oba-result-saved > h2 span:first-child {
		font-size: 30px;
	}

	.oba-result > div:first-child > div:last-child {
		flex: 1.08 1;
	}

	.oba-result-contact > div {
		max-width: none;
		max-width: initial;
	}

	.oba-result-contact > div > div {
		flex: 1 1;
	}

	.oba-result-contact > div > div:nth-child(odd) {
		margin-right: 20px;
	}

	.oba-result-contact > div > div:nth-child(even) {
		margin-left: 20px;
	}

	.oba-result-contact > div > div.terms {
		padding-top: 30px;
	}

	.oba-result-contact > div > div.terms label {
		font-size: 1em;
	}

	.oba-result-contact > div > div a {
		align-self: flex-start;
	}
}

@media (min-width: 1024px) {
	.oba-result-market h3,
	.oba-result-partner h3 {
		font-size: 1.4em;
	}

	.oba-result-market h5 span,
	.oba-result-partner h5 span {
		font-size: 1em;
	}

	.oba-result > div:first-child > div {
		padding: 25px 25px 20px 25px !important;
	}
}

